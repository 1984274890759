import { prepGallerySizes, Layout } from 'components/_shared/widgets/gallery';
import {
  HERO_GALLERY_LAYOUT,
  HERO_GALLERY_SIZE,
} from 'components/_shared/widgets/gallery/hero-gallery';
import { getRandomColor } from 'components/shop';
import {
  ProductInterface,
  Shop,
  GalleryItem,
  PromoBlockInterface,
  GalleryImageInterface,
} from 'types/types';
import { isLimitedTimeDeal } from '../../../../util/products';
import { chunkArray } from '../../../../util/arrays';
import { isImage, isProduct } from 'types/guards/gallery';
import { ListingItem, ListingTypeEnum, isGallery } from './types';

type ShopWithGalleryItems = Shop & {
  items: GalleryItem[];
};

/**
 * Sort funcs.
 */
const positionSort = (a: GalleryItem, b: GalleryItem) =>
  Number(a.position) - Number(b.position);

const soldOutGalleryItemSort = (a: GalleryItem, b: GalleryItem) =>
  Number(isProduct(a) ? a.isSoldOut : false) -
  Number(isProduct(b) ? b.isSoldOut : false);

const soldOutProductSort = (a: ProductInterface, b: ProductInterface) =>
  Number(a.isSoldOut) - Number(b.isSoldOut);

/**
 * Data/props preparation.
 */
export const prepProductListing = ({
  products,
  promoBlocks,
  withHero,
  withShops,
  minItemsPerShop,
  canAddPromoBockToHeroGallery,
  gallerySize,
  maxGalleriesPerShop,
  galleryLayouts,
}: {
  products: ProductInterface[];
  promoBlocks?: PromoBlockInterface[];
  withHero?: boolean;
  withShops?: boolean;
  minItemsPerShop?: number;
  canAddPromoBockToHeroGallery?: boolean;
  gallerySize: number;
  maxGalleriesPerShop: number;
  galleryLayouts: Layout[];
}): ListingItem[] => {
  const listingItems: ListingItem[] = [];

  let items: GalleryItem[] = !withShops
    ? products
    : products.filter(item => !item.shop && !isLimitedTimeDeal(item));

  if (promoBlocks) {
    const typedPromoBlocks: GalleryImageInterface[] = [];
    promoBlocks?.forEach(promoBlock =>
      typedPromoBlocks.push({
        ...promoBlock,
        _galleryType: 'image',
      })
    );
    items = [...items, ...typedPromoBlocks];
  }

  if (canAddPromoBockToHeroGallery) {
    items.sort(positionSort);
  }

  if (withHero) {
    const heroItems = items.splice(0, HERO_GALLERY_SIZE);
    heroItems.sort(soldOutGalleryItemSort);

    listingItems.push({
      type: ListingTypeEnum.Hero,
      props: {
        items: heroItems,
        layout: prepGallerySizes(heroItems.length, HERO_GALLERY_LAYOUT),
      },
    });
  }

  if (!canAddPromoBockToHeroGallery) {
    items.sort(positionSort);
  }

  let shops: ShopWithGalleryItems[] = [];
  if (withShops) {
    const unorderedShops = products
      .filter(item => !!item.shop && !isLimitedTimeDeal(item))
      .sort(soldOutProductSort)
      .reduce(
        (
          shops: Record<string, ShopWithGalleryItems>,
          item: ProductInterface
        ) => {
          if (item.shop) {
            const idx = item.shop.name;
            shops[idx] = shops.hasOwnProperty(idx)
              ? shops[idx]
              : {
                  ...item.shop,
                  items: item.shop.image
                    ? [
                        {
                          position: 0,
                          image: item.shop.image,
                          _galleryType: 'image',
                        },
                      ]
                    : [],
                };
            shops[idx].items.push(item);
          }
          return shops;
        },
        {}
      );

    shops = Object.values<ShopWithGalleryItems>(unorderedShops).sort(
      (a, b) => a.position - b.position
    );
  }

  const totalShops = shops.length;
  const gallerySpread = Math.ceil(items.length / gallerySize);
  const galleriesPerShop = Math.min(
    Math.floor(gallerySpread / totalShops),
    maxGalleriesPerShop
  );

  /**
   * Ensures that the first thing in the body is a shop.
   * This is because we have a custom hero gallery that is rendered before the "Body".
   */
  let galleriesSinceShop = withHero ? 99999 : 0;
  let galleries = 0;

  while (shops.length > 0 || items.length > 0) {
    if (
      shops.length > 0 &&
      (!items.length || galleriesSinceShop >= galleriesPerShop)
    ) {
      const shop: ShopWithGalleryItems | undefined = shops.shift();

      if (shop) {
        // we need to know if the shop has an image so we can do the minItemsPerShop check
        const hasImage = isImage(shop.items[0]);
        const itemCount = hasImage ? shop.items.length - 1 : shop.items.length;
        if (
          typeof minItemsPerShop !== 'undefined' &&
          itemCount < minItemsPerShop
        ) {
          const shopItems = hasImage ? shop.items.slice(1) : shop.items;
          if (items.length > 0) {
            // push these into the next gallery and shove the entire layout down a bit
            items.unshift(...shopItems);
          } else {
            // see if we can push these up into the previous gallery
            // NOTE: using reverse on a new array because findLast is only available from node 18
            const prevGallery = [...listingItems]
              .reverse()
              .find(({ type }) => type === ListingTypeEnum.Gallery);
            if (
              prevGallery &&
              isGallery(prevGallery) &&
              prevGallery.props.items.length < gallerySize
            ) {
              // push some items into the last gallery
              const pushUpCount = gallerySize - prevGallery.props.items.length;
              const pushUpItems = shopItems.splice(0, pushUpCount);
              const newGalleryItems = [
                ...prevGallery.props.items,
                ...pushUpItems,
              ];
              prevGallery.props = {
                items: newGalleryItems,
                layout: prepGallerySizes(
                  newGalleryItems.length,
                  prevGallery.layout
                ),
              };
            }

            // any items not pushed up will go into the next gallery
            if (shopItems.length > 0) {
              items.unshift(...shopItems);
            }
          }
        } else {
          listingItems.push({
            type: ListingTypeEnum.Shop,
            props: {
              ...shop,
              ...(!shop.color ? { color: getRandomColor() } : {}),
              totalItems: shop.items.length,
              items: shop.items,
            },
          });
          galleriesSinceShop = 0;
        }
      }
    }

    if (items.length > 0) {
      const galleryItems = items.splice(0, gallerySize);
      const layoutIndex = galleries % galleryLayouts.length;

      listingItems.push({
        type: ListingTypeEnum.Gallery,
        layout: galleryLayouts[layoutIndex],
        props: {
          items: galleryItems,
          layout: prepGallerySizes(
            galleryItems.length,
            galleryLayouts[layoutIndex]
          ),
        },
      });

      galleriesSinceShop++;
      galleries++;
    }
  }

  return listingItems;
};
